import React from "react";
import { Link } from "react-router-dom";
import { FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa";

export default () => {
  return (
    <footer className="footer mt-auto py-3">
      <div className="">
        <span className="footerItems">
          {/* <a href="https://suporte.centralcorona.com/hc/pt-br/articles/360007077717-Termos-de-Uso">
            Termos
          </a>{" "} <span>|</span>  */}
          <Link to="/voluntario">Seja um médico voluntário</Link>
          <span>|</span> <Link to="/login">Login</Link>
          <span>|</span>{" "}
          <a href="/">
            Contato
          </a>
          <span>|</span>
          <div className="socialmedia">
            <a
              href="https://facebook.com/centralcorona"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook size="1.3em" />
            </a>
            <a
              href="https://twitter.com/central_corona"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter size="1.3em" />
            </a>
            <a
              href="https://instagram.com/central_corona"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram size="1.3em" />
            </a>
          </div>
        </span>
      </div>
    </footer>
  );
};
