import React, { useState, useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { Jumbotron, Button, Form } from "react-bootstrap";
import firebase from "./vendors/firebase";
import Loading from "./Loading";
import { Table, Tag, Switch } from "antd";
import { Redirect } from "react-router-dom";
import "antd/dist/antd.css";
import { Tooltip } from "antd";

const db = firebase.firestore();

function AdminPanel() {
  const [filterName, setFilterName] = useState(null);
  const [filterAprovado, setFilterAprovado] = useState("Todos");
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [user, loadingUser, errorUser] = useAuthState(firebase.auth());
  const [value, loading, error] = useCollection(db.collection("medicos"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    const filtered = value?.docs
      ?.filter((i) => {
        if (!filterName) return true;
        return i
          .data()
          ?.nome?.toLowerCase()
          .includes(filterName?.toLowerCase());
      })
      .filter((i) => {
        return (
          i.data()?.aprovado ===
          (filterAprovado === "Todos"
            ? i.data()?.aprovado
            : filterAprovado === "Pendentes"
            ? undefined
            : true)
        );
      });
    setFilteredUsers(filtered);
  }, [filterName, value, filterAprovado]);

  const onChangeApproved = (isAprovado, data) => {
    const { uid } = data;
    db.collection("medicos")
      .doc(uid)
      .update({
        aprovado: !isAprovado,
        aprovadoPor: !isAprovado ? user.displayName : null,
      });
  };

  const onChangeFilterName = (e) => {
    setFilterName(e.target.value);
  };

  const onChangeFilterAprovado = (e) => {
    setFilterAprovado(
      e?.target?.options[e?.target?.options?.selectedIndex].value
    );
  };

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.replace("/admin");
      });
  };

  if (loading || loadingUser) {
    return <Loading />;
  }

  if (errorUser) {
    return <Redirect to="/admin" />;
  }

  if (error) {
    return (
      <Jumbotron>
        <h4>Usuário não autorizado</h4>
        <Button size="sm" onClick={handleLogout}>
          LOGOUT
        </Button>
      </Jumbotron>
    );
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "nome",
      key: "nome",
      render: (nome, data) => {
        return (
          <Tooltip title={`Referral ID: ${data.ref || "Nenhum"}`}>
            <span>{nome}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
    },
    {
      title: "Telefone",
      dataIndex: "telefone",
      key: "telefone",
    },
    {
      title: "CRM",
      dataIndex: "crm",
      key: "crm",
      render: (crm, data) => {
        return (
          <a
            href={`https://portal.cfm.org.br/index.php?option=com_medicos&nomeMedico=${data.nome}&ufMedico=${data.estado}&crmMedico=${data.crm}&municipioMedico=&tipoInscricaoMedico=&situacaoMedico=&detalheSituacaoMedico=&especialidadeMedico=&areaAtuacaoMedico=&g-recaptcha-response=03AHaCkAZyvSUrcjJ_qNGAG1MOjxoxNqd56pGtdqkzoAE7nZQF6uI-iXANNDQgKLFkaVF1F7lgKbJDoe-LyBFWUM8STyrKRsbhih3o8Dg7lKdmk3Vg42zPcRS_MISUVCmJ3-NFukkp2UW7Z8Vl1UGX8qU-KWMjRjeiBTLeU6r9UYQMaSYLcb0nk_ZcLxe9mW170Au_A9CZOthJBtorE8kDYqW-miURL92gkJVQXMkQUyv4zYkynlfMfk_CQaPvbi5JnK672Pg7im0Q77QWOTckdci8G25QER3tB2NrSdr4UsYhUXmOmu_HYRuXBMFkQd88zJKVY7BpFiFzXSeAqEt2Itj5Ime4R2aN4JZ8EDyxoJ8rquJyRYWPdmHKF7BE9djBdTY9sYcuByUB2IXl9DMtI2ixByCpwJhoeYS79oBrjfqU4geU8QUnBmSeUMd8zANzqXBR_ty9NyL8NpbSQrwNd8J5ewCnksSNPw`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {crm}
          </a>
        );
      },
    },
    {
      title: "Especialidades",
      key: "especialidades",
      dataIndex: "especialidades",
      render: (especialidades) => (
        <span>
          {especialidades?.map((i, key) => {
            return <Tag key={key}>{i.value.toUpperCase()}</Tag>;
          })}
        </span>
      ),
    },
    {
      title: "Aprovado",
      key: "aprovado",
      dataIndex: "aprovado",
      render: (isAprovado, data) => (
        <span>
          <Switch
            checked={isAprovado}
            onChange={() => onChangeApproved(isAprovado, data)}
          />
        </span>
      ),
    },
  ];

  const data = filteredUsers?.map((doc) => {
    return { ...doc.data(), uid: doc.id };
  });

  if (user) {
    return (
      <Jumbotron>
        <h4>Médicos cadastrados</h4>
        <p style={{ color: "silver" }}>
          <strong>Logado como:</strong> {user.displayName} ({user.email}) <br />
          <br />
          <div style={{ maxWidth: "600px", margin: "0 auto" }}>
            <Form.Group>
              <Form.Label>Filtros</Form.Label>
              <Form.Control
                id="nome"
                type="text"
                value={filterName}
                onChange={onChangeFilterName}
                placeholder="Entre com nome"
              />
            </Form.Group>
            <Form.Control
              as="select"
              value={filterAprovado}
              onChange={onChangeFilterAprovado}
            >
              <option>Todos</option>
              <option>Aprovados</option>
              <option>Pendentes</option>
            </Form.Control>
          </div>
        </p>
        <Table columns={columns} dataSource={data} />
        <Button size="sm" onClick={handleLogout}>
          LOGOUT
        </Button>
      </Jumbotron>
    );
  }

  return <Redirect to="/admin" />;
}

export default AdminPanel;
