import React from "react";
import "./css/loader.css";

function Loading() {
  return (
    <div style={{ textAlign: "center", margin: "30px" }}>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loading;
