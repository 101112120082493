import React, { useState } from "react";
import Loading from "./Loading";
import { Redirect } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import Rating from "react-rating";
import { Jumbotron, Form, Button } from "react-bootstrap";
import axios from "axios";

import "./css/Voluntario.css";

function Voluntario(props) {
  const [loading, setLoading] = useState(false);
  const [review, setReview] = useState({ stars: 3, comentario: null });
  const conversationId = props.location.pathname.split("review/")[1];

  if (!conversationId) return <Redirect to="/" />;

  const handleStar = (e) => {
    const newReview = review;
    review.stars = e;
    setReview(newReview);
  };

  const handleComment = (e) => {
    const newReview = review;
    review.comentario = e.target.value;
    setReview(newReview);
  };

  const submitReview = async (e) => {
    if (!conversationId || !review.stars) return;

    setLoading(true);
    try {
      await axios.post(
        "https://us-central1-centralcorona-b38ac.cloudfunctions.net/addReview",
        {
          stars: review.stars,
          comentario: review.comentario || "",
          conversationId,
        }
      );
      window.location.replace("/");
    } catch (error) {
      window.location.replace("/");
    }
  };

  if (loading) return <Loading />;

  return (
    <Jumbotron>
      <h4>Selecione seu nível de satisfação com o atendimento:</h4>
      <br />
      <Rating
        onChange={handleStar}
        placeholderRating={3.5}
        emptySymbol={<FaStar size="3em" color="silver" />}
        placeholderSymbol={<FaStar size="3em" color="#f6c943" />}
        fullSymbol={<FaStar size="3em" color="#f6c943" />}
      />
      <br />
      <br />

      <Form.Group>
        <Form.Label>Deixe seu comentário</Form.Label>
        <Form.Control
          style={{ maxWidth: "500px", margin: "0 auto" }}
          id="nome"
          required
          value={review.comentario}
          onChange={handleComment}
          as="textarea"
          rows="3"
          placeholder="ex: Atendimento excelente!"
        />
      </Form.Group>
      <Button onClick={submitReview}>Enviar avaliação</Button>
    </Jumbotron>
  );
}

export default Voluntario;
