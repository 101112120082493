import React, { useState } from "react";
import Select from "react-select";
import { Button, Jumbotron, Form } from "react-bootstrap";
import especialidades from "./data/Especialidades";
import Loading from "./Loading";
import { Link, Redirect } from "react-router-dom";
import firebase from "./vendors/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const db = firebase.firestore();

function Voluntario(props) {
  const { ref } = props?.location?.state || { ref: null };
  const [user, initialising, errorLogin] = useAuthState(firebase.auth());
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    estado: "AC"
  });

  console.log(ref);

  const [completed, setCompleted] = useState(false);
  const [erroed, setError] = useState(false);

  const handleForm = e => {
    const newData = userData;
    newData[e.target.id] = e.target.value;
    setUserData(newData);
  };

  if (!user) {
    return <Redirect to="/voluntario" />;
  }

  const { photoURL, displayName, uid, email } = user;

  const handleEspecialidades = e => {
    const newData = userData;
    setUserData({
      ...newData,
      especialidades: e.map(i => {
        return {
          ...i,
          value: i.label
            .toLowerCase()
            .replace(/ /g, "-")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        };
      })
    });
  };

  // const handlePreferencias = e => {
  //   const newData = userData;
  //   setUserData({
  //     ...newData,
  //     preferencias: e
  //   });
  // };

  const formSubmit = e => {
    setLoading(true);
    e.preventDefault();
    db.collection("medicos")
      .doc(uid)
      .set({ ...userData, ref: ref || null, displayName, email, photoURL })
      .then(function() {
        setLoading(false);
        console.log("Document successfully written!");
        setCompleted(true);
      })
      .catch(function(error) {
        setLoading(false);
        setError(error);
        console.error("Error writing document: ", error);
      });
  };

  if (loading || initialising) {
    return <Loading />;
  }

  if (erroed || errorLogin) {
    return (
      <Jumbotron>
        <h4>Erro!</h4>
        <p>
          Erro ao submeter seu formulário, caso já tenha criado uma conta,
          efetue o <Link to="/login">Login</Link>, ou tente novamente mais
          tarde.
        </p>
      </Jumbotron>
    );
  }

  if (completed) {
    return <Redirect to="/painel" />;
  }

  return (
    <Jumbotron>
      <h1>Finalizar Cadastro</h1>

      <p style={{ fontSize: "16px" }}>
        Por favor preencha o formulário abaixo para concluir seu cadastro.
      </p>
      <div style={{ maxWidth: "500px", margin: "0 auto" }}>
        <Form onSubmit={formSubmit}>
          <Form.Group>
            <Form.Label>Nome completo</Form.Label>
            <Form.Control
              id="nome"
              required
              value={userData.nome}
              onChange={handleForm}
              type="text"
              placeholder="ex: Fernando Sousa Filho"
            />
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              id="email"
              value={userData.email}
              onChange={handleForm}
              type="email"
              placeholder="ex: nome@exemplo.com"
            />
          </Form.Group> */}
          <Form.Group>
            <Form.Label>Celular (WhatsApp)</Form.Label>
            <Form.Control
              id="telefone"
              required
              feedback="Por favor preencha seu telefone"
              pattern="^[0-9]{9,}$"
              value={userData.telefone}
              onChange={handleForm}
              type="number"
              placeholder="ex: 8399990000"
            />
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>CEP</Form.Label>
            <Form.Control
              required
              id="cep"
              value={userData.cep}
              onChange={handleForm}
              type="text"
              placeholder="ex: 58037-999"
            />
          </Form.Group> */}
          <Form.Group>
            <Form.Label>Número do CRM</Form.Label>
            <Form.Control
              id="crm"
              feedback="Por favor preencha seu CRM"
              required
              value={userData.crm}
              onChange={handleForm}
              type="text"
              placeholder="ex: 8888"
            />
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>Número do CPF</Form.Label>
            <Form.Control
              id="cpf"
              required
              value={userData.cpf}
              onChange={handleForm}
              pattern="([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})"
              type="text"
              placeholder="ex: 04700000000"
            />
          </Form.Group> */}
          <Form.Group>
            <Form.Label>Estado do CRM</Form.Label>
            <Form.Control
              required
              onChange={handleForm}
              id="estado"
              selectedValue={userData.estado}
              as="select"
            >
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Especialidades</Form.Label>
            <Select
              onChange={handleEspecialidades}
              placeholder="Selecione uma ou mais..."
              isMulti
              name="especialidaddes"
              options={especialidades}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>Opções de atendimento</Form.Label>
            <p style={{ color: "silver" }}>
              Sendo voluntário, você passa a receber eventuais notificações e
              poderá prestar atendimento gratuito para cidadãos de todo Brasil
              no combate ao COVID19. Não voluntários ainda podem utilizar a
              plataforma de forma gratuita para o uso da Telemedicina com seus
              proprios pacientes.
            </p>
            <Select
              required
              onChange={handlePreferencias}
              placeholder="Selecione uma ou mais..."
              isMulti
              name="atendimentos"
              defaultValue={[
                { label: "Voluntário", value: "open" },
                {
                  label: "Meus pacientes",
                  value: "private"
                }
              ]}
              options={[
                { label: "Voluntário", value: "open", selected: "true" },
                {
                  label: "Meus pacientes",
                  value: "private"
                }
              ]}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Form.Group> */}
          <p>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                required
                type="checkbox"
                label={
                  <div>
                    Estou de acordo com os{" "}
                    <a
                      href="https://centralcorona.zendesk.com/hc/pt-br/articles/360007077717-Termos-de-Uso"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Termos de uso
                    </a>
                  </div>
                }
                feedback="Você precisa concordar com os temos de uso"
              />
            </Form.Group>
          </p>
          <Button type="submit"> Submeter cadastro</Button>
        </Form>
      </div>
    </Jumbotron>
  );
}

export default Voluntario;
