import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Jumbotron, Card, CardGroup } from "react-bootstrap";
import fuze from "./img/partners/fuze.png";
import bird from "./img/partners/bird.svg";
import enlink from "./img/partners/enlink.png";
import moises from "./img/partners/moises.svg";
import firebase from "./vendors/firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import Loading from "./Loading";

const db = firebase.firestore();

function Equipe() {

  const seo_attr = {
    title: "Conheça Nossa Equipe | Central Corona",
    description: "Conheça quem são nossos fundadores, médicos voluntários e voluntários " +
                 "operacionais. Venha fazer parte do nosso time na luta contra o Coronavírus " +
                 "(COVID-19)."
  };

  document.title = seo_attr.title;
  document.getElementsByTagName("meta").namedItem("description").content = seo_attr.description;

  const [value, loading, error] = useCollection(
    db.collection("profiles").where("medico", "==", true),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (error)
    return <div style={{ marginTop: "40px" }}>Error, please try again.</div>;

  if (loading) return <Loading />;

  const VoluntariosCards = () => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {value.docs.map((item) => {
          const { displayName, crm, estado, photoUrl } = item.data();
          const trimmedName = displayName.split(" ").slice(0, 4).join(" ");
          return (
            <Card
              style={{
                width: "240px",
                height: "11rem",
                margin: "10px auto",
              }}
            >
              <Card.Body>
                <Card.Title>
                  <img
                    style={{ filter: "grayscale(100%)" }}
                    alt={displayName}
                    width="60px"
                    height="60px"
                    className="rounded-circle"
                    src={photoUrl}
                  />
                  <br />
                  {trimmedName}
                </Card.Title>
                <Card.Link
                  target="_blank"
                  href={`https://portal.cfm.org.br/index.php?option=com_medicos&nomeMedico=${trimmedName}&ufMedico=${estado}&crmMedico=${crm}&municipioMedico=&tipoInscricaoMedico=&situacaoMedico=&detalheSituacaoMedico=&especialidadeMedico=&areaAtuacaoMedico=&g-recaptcha-response=03AHaCkAZyvSUrcjJ_qNGAG1MOjxoxNqd56pGtdqkzoAE7nZQF6uI-iXANNDQgKLFkaVF1F7lgKbJDoe-LyBFWUM8STyrKRsbhih3o8Dg7lKdmk3Vg42zPcRS_MISUVCmJ3-NFukkp2UW7Z8Vl1UGX8qU-KWMjRjeiBTLeU6r9UYQMaSYLcb0nk_ZcLxe9mW170Au_A9CZOthJBtorE8kDYqW-miURL92gkJVQXMkQUyv4zYkynlfMfk_CQaPvbi5JnK672Pg7im0Q77QWOTckdci8G25QER3tB2NrSdr4UsYhUXmOmu_HYRuXBMFkQd88zJKVY7BpFiFzXSeAqEt2Itj5Ime4R2aN4JZ8EDyxoJ8rquJyRYWPdmHKF7BE9djBdTY9sYcuByUB2IXl9DMtI2ixByCpwJhoeYS79oBrjfqU4geU8QUnBmSeUMd8zANzqXBR_ty9NyL8NpbSQrwNd8J5ewCnksSNPw`}
                >
                  CRM: {crm} - {estado}
                </Card.Link>
              </Card.Body>
            </Card>
          );
        })}
      </div>
    );
  };

  return (
    <Jumbotron
      className="equipe"
      style={{ maxWidth: "800px", margin: "0 auto" }}
    >
      <h1>Nossa Equipe</h1>
      <h4>Fundadores</h4>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Card
          style={{
            width: "240px",
            height: "11rem",
            margin: "10px auto",
          }}
        >
          <Card.Body>
            <Card.Title>
              <img
                alt="Geraldo Ramos"
                width="60px"
                height="60px"
                className="rounded-circle"
                style={{ filter: "grayscale(100%)" }}
                src="https://firebasestorage.googleapis.com/v0/b/centralcorona-b38ac.appspot.com/o/fotos%2Fgeraldo.png?alt=media&token=17228064-b2bc-4eba-a4cd-244f09d4833b"
              />
              <br />
              Geraldo Ramos
            </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              Tecnologista
            </Card.Subtitle>
            <Card.Link
              target="_blank"
              href="https://www.linkedin.com/in/geraldoramos/"
            >
              Linkedin
            </Card.Link>
          </Card.Body>
        </Card>
        <Card
          style={{
            width: "240px",
            height: "11rem",
            margin: "10px auto",
          }}
        >
          <Card.Body>
            <Card.Title>
              <img
                alt="Eddie Gueiros"
                width="60px"
                height="60px"
                className="rounded-circle"
                style={{ filter: "grayscale(100%)" }}
                src="https://firebasestorage.googleapis.com/v0/b/centralcorona-b38ac.appspot.com/o/fotos%2Feddie.png?alt=media&token=9e3275b6-cde9-4fed-bc67-a8b76c146048"
              />
              <br />
              Eddie Gueiros
            </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              Tecnologista
            </Card.Subtitle>
            <Card.Link
              target="_blank"
              href="https://www.linkedin.com/in/eddiegueiros/"
            >
              Linkedin
            </Card.Link>
          </Card.Body>
        </Card>
        <Card
          style={{
            width: "240px",
            height: "11rem",
            margin: "10px auto",
          }}
        >
          <Card.Body>
            <Card.Title>
              <img
                style={{ filter: "grayscale(100%)" }}
                alt="Heloísa Estrela"
                width="60px"
                height="60px"
                className="rounded-circle"
                src="https://firebasestorage.googleapis.com/v0/b/centralcorona-b38ac.appspot.com/o/fotos%2Fhelo.png?alt=media&token=6a1c8149-49da-4702-be13-eb02afb9776d"
              />
              <br />
              Heloísa Estrela
            </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              Médica responsável
            </Card.Subtitle>
            <Card.Link
              target="_blank"
              href="https://portal.cfm.org.br/index.php?option=com_medicos&nomeMedico=Heloisa&ufMedico=PB&crmMedico=8659&municipioMedico=&tipoInscricaoMedico=&situacaoMedico=&detalheSituacaoMedico=&especialidadeMedico=&areaAtuacaoMedico=&g-recaptcha-response=03AHaCkAaeH0kHeu3kvrQa_VIrg2Z5cLcNqXK0rRJYw8Boh8G1AFatmamhpIKTeR-l-Qk6mctkKos8A-3bHIoOlrBvT6jy2loqE0Bj40oOINptARd8n4SBiwssUImNKuZMtWWqUCN4Ml6XG-17OJW976imRwpPXyeZjZBpNEmz0w3bcCLw0HI4ijJWRpvnaLomay7jQE2RU_MPx7AwkVlGaBA0ViL9mWxN7qiDyRRidBP4jAWL9hG1wkWnX-HtX6BITAec3VFNgeC0oyjQRY7bQZsR4azXSrWu83g12xojeXWA2PtTwLQNSrHsGM54UENcgSoDgIh8MEjoyKQQK0peIgV8CwXHoCQUhdEdjNGfpLZPNuDRQO_Peyi4ELPYMbK8QPJ8J2OoVt2IzflwBjLvTaVFkMgpoMKU9fHDrWiL-pWMXaRvoeY7rYiltTTdcBFtDuzyNfoUHC7TUOo48ca3CihFDHWVskqw_Q"
            >
              CRM: 8659 - PB
            </Card.Link>
          </Card.Body>
        </Card>
      </div>
      <br />
      <h4>Médicos Voluntários</h4>

      <VoluntariosCards />

      <br />
      <h4>Voluntários Operacionais</h4>
      <CardGroup>
        <Card style={{ width: "18rem", margin: "10px auto" }}>
          <Card.Body>
            <Card.Title>Thiago Medeiros</Card.Title>
            <Card.Link
              target="_blank"
              href="https://www.linkedin.com/in/medeirosthiago/"
            >
              Desenvolvedor
            </Card.Link>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem", margin: "10px auto" }}>
          <Card.Body>
            <Card.Title>Renata Costa Almeida</Card.Title>
            <Card.Link
              target="_blank"
              href="https://www.linkedin.com/in/renatacostaalmeida/"
            >
              Gestora de projetos
            </Card.Link>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem", margin: "10px auto" }}>
          <Card.Body>
            <Card.Title>José Wilker Tarradt</Card.Title>
            <Card.Link
              target="_blank"
              href="https://www.linkedin.com/in/josewilker/"
            >
              Tecnologista
            </Card.Link>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem", margin: "10px auto" }}>
          <Card.Body>
            <Card.Title>Natacha Fernandes</Card.Title>
            <Card.Link
              target="_blank"
              href="https://www.linkedin.com/in/natacha-fernandes-37ba09b3/"
            >
              Marketing Digital
            </Card.Link>
          </Card.Body>
        </Card>
      </CardGroup>
      <CardGroup>
        <Card style={{ width: "18rem", margin: "10px auto" }}>
          <Card.Body>
            <Card.Title>Amanda Medeiros </Card.Title>
            <Card.Link
              target="_blank"
              href="https://www.linkedin.com/in/amanda-medeiros-de-souza-5b5763186/"
            >
              Comunicação
            </Card.Link>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem", margin: "10px auto" }}>
          <Card.Body>
            <Card.Title>Susane Guimarães</Card.Title>
            <Card.Link
              target="_blank"
              href="https://www.linkedin.com/in/susane-guimaraes/"
            >
              Comunicação
            </Card.Link>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem", margin: "10px auto" }}>
          <Card.Body>
            <Card.Title>Manuela Sanches</Card.Title>
            <Card.Link
              target="_blank"
              href="https://www.linkedin.com/in/msanches/"
            >
              Comunicação
            </Card.Link>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem", margin: "10px auto" }}>
          <Card.Body>
            <Card.Title>Rafael Guimarães</Card.Title>
            <Card.Link
              target="_blank"
              href="https://www.linkedin.com/in/guimaraesrafa/?fbclid=IwAR0bY3sDj3K1JLf67s8bn4oloXWlzUw3ToAS2OXjEvbiY6l-RgnFGYzrpGU"
            >
              Marketing Digital
            </Card.Link>
          </Card.Body>
        </Card>
      </CardGroup>
      <CardGroup>
        <Card style={{ width: "18rem", margin: "10px auto" }}>
          <Card.Body>
            <Card.Title>Elyn Hsu </Card.Title>
            <Card.Link
              target="_blank"
              href="https://www.linkedin.com/in/elyn-hsu/"
            >
              Recursos Humanos
            </Card.Link>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem", margin: "10px auto" }}>
          <Card.Body>
            <Card.Title>Thiago Mendes </Card.Title>
            <Card.Link
              target="_blank"
              href="https://www.linkedin.com/in/thiago-mendes-5206b3166/"
            >
              Designer Gráfico
            </Card.Link>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem", margin: "10px auto" }}>
          <Card.Body>
            <Card.Title>Caio Borges</Card.Title>
            <Card.Link
              target="_blank"
              href="https://www.linkedin.com/in/caiodsborges/"
            >
              Jurídico
            </Card.Link>
          </Card.Body>
        </Card>
      </CardGroup>
      <br />
      <h4>Empresas apoiadoras</h4>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a target="_blank" href="https://moises.ai" rel="noopener noreferrer">
          <img className="moises" src={moises} alt="Moises" />
        </a>
        <a target="_blank" href="https://fuze.cc" rel="noopener noreferrer">
          <img className="fuze" src={fuze} alt="Fuze"></img>
        </a>
        <a
          target="_blank"
          href="https://www.messagebird.com/en/inbox-for-good"
          rel="noopener noreferrer"
        >
          <img className="bird" src={bird} alt="MessageBird"></img>
        </a>

        <a
          target="_blank"
          href="http://agenciaenlink.com.br/"
          rel="noopener noreferrer"
        >
          <img className="enlink" src={enlink} alt="Enlink"></img>
        </a>
      </div>
    </Jumbotron>
  );
}

export default Equipe;
