import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

import { Navbar, Nav } from "react-bootstrap";
import logo from "./img/logo2.svg";

function App() {
  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand style={{ marginLeft: "20px" }}>
        <Link to="/">
          <img src={logo} width="180px" alt="Central Corona" />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle
        className="navbar-light"
        aria-controls="basic-navbar-nav"
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link>
            <Link to="/atendimento-coronavirus">
              <div className="menuItem">
                {/* <FaHeadset className="icons" /> */}
                <span>Atendimento</span>
              </div>
            </Link>
          </Nav.Link>
          <Nav.Link>
            <Link to="/voluntario">
              <div className="menuItem">
                {/* <FaHandsHelping className="icons" /> */}
                <span>Seja Médico Voluntário</span>
              </div>
            </Link>
          </Nav.Link>
          <Nav.Link>
            <Link to="/login">
              <div className="menuItem">
                {/* <FaHandsHelping className="icons" /> */}
                <span>Login</span>
              </div>
            </Link>
          </Nav.Link>
          <Nav.Link>
            <Link to="/equipe">
              <div className="menuItem">
                {/* <FaHandsHelping className="icons" /> */}
                <span>Equipe</span>
              </div>
            </Link>
          </Nav.Link>
          <Nav.Link>
            <Link to="/faq">
              <div className="menuItem">
                {/* <FaQuestionCircle className="icons" /> */}
                <span>FAQ</span>
              </div>
            </Link>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default App;
