import * as firebase from "firebase";

const config = {
  apiKey: "AIzaSyA4berJ6xZX0_34Bdg_mIii_5nByp6gFoE",
  authDomain: "centralcorona.com",
  databaseURL: "https://centralcorona-b38ac.firebaseio.com",
  projectId: "centralcorona-b38ac",
  storageBucket: "centralcorona-b38ac.appspot.com",
  messagingSenderId: "779471587489",
  appId: "1:779471587489:web:793bcc904fbaeb4f0ec8e0",
  measurementId: "G-ZGFK4QMHHZ",
};

firebase.initializeApp(config);

export default firebase;
