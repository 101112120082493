import React, { useState, useEffect } from "react";
import { Jumbotron, Card, Button } from "react-bootstrap";
import firebase from "./vendors/firebase";
import Loading from "./Loading";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";

const db = firebase.firestore();

function Voluntario(props) {
  const { ref } = props?.location?.state || { ref: null };
  const [loading, setLoading] = useState(false);
  const [user, initialising, errorLogin] = useAuthState(firebase.auth());
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (!initialising) {
      setLoading(true);
      db.collection("medicos")
        .doc(user.uid)
        .get()
        .then((doc) => {
          setLoading(false);
          setUserData(doc.data());
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [user, initialising]);

  const handleLogout = () => {
    setLoading(true);
    firebase
      .auth()
      .signOut()
      .then(() => {
        setLoading(false);
        window.location.replace("/login");
      });
  };

  if (initialising || loading) {
    return <Loading />;
  }

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { ref },
        }}
      />
    );
  }

  if (errorLogin) {
    return (
      <Jumbotron>
        <h4>Erro! Favor tentar novamente mais tarde.</h4>
      </Jumbotron>
    );
  }

  // if (user && !userData) {
  //   return (
  //     <Jumbotron>
  //       <h4 style={{ color: "#1d1d1d" }}>Painel Médico</h4>
  //       <p>Seu cadastro não foi completado</p>
  //       <Link
  //         to={{
  //           pathname: "/cadastro",
  //           state: {
  //             displayName: user.displayName,
  //             uid: user.uid,
  //             photoURL: user.photoURL,
  //             email: user.email
  //           }
  //         }}
  //       >
  //         <Button>Completar Cadastro</Button>
  //       </Link>
  //     </Jumbotron>
  //   );
  // }

  return (
    <Jumbotron>
      <div>
        <Button size="sm" variant="link" onClick={handleLogout}>
          SAIR
        </Button>
      </div>
      <h4 style={{ color: "#1d1d1d" }}>Painel Médico</h4>{" "}
      <div
        style={{
          textAlign: "center",
        }}
      >
        <img
          width="60px"
          height="60px"
          className="rounded-circle"
          style={{ marginBottom: "10px" }}
          src={user.photoURL}
          alt="Foto médico"
        />

        <div>
          <p>
            <span className="dados">Nome:</span>{" "}
            {userData?.nome || user.displayName}
            <br />
            <span className="dados">Email:</span> {user.email}
            <br />
            <span className="dados">CRM: </span>
            {userData
              ? `${userData?.crm}(${userData.estado})`
              : "Não fornecido"}
            <br />
            <span className="dados">Situação cadastral: </span>
            {userData?.aprovado
              ? "Aprovado"
              : userData
              ? "Em análise"
              : "Cadastro incompleto"}
            <br />
            <br />
            {!userData ? (
              <div>
                <Link
                  to={{
                    pathname: "/cadastro",
                    state: { ref },
                  }}
                >
                  <Button>Completar Cadastro</Button>
                </Link>
                <br />
              </div>
            ) : null}
          </p>
        </div>
      </div>
      <Card>
        <Card.Body>
          <Card.Title>Acesso ao Inbox</Card.Title>
          <Card.Text>
            A plataforma Inbox permite atender aos chamados dos pacientes. É
            necessário o acesso via desktop ou tablet. Acesso via dispositivos
            móveis com telas de baixa resolução não é possível no momento.
            <br />
          </Card.Text>
          <a
            href="http://inbox.messagebird.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button disabled={!userData?.aprovado} variant="primary">
              Acessar Inbox
            </Button>
          </a>
          <br />
          <small>
            {userData?.aprovado ? (
              "Verifique seu e-mail para criação da sua conta na plataforma MessageBird"
            ) : userData ? (
              "Seu cadastro ainda não foi aprovado"
            ) : (
              <span>
                Você precisa completar seu cadastro,{" "}
                <Link to="cadastro">Clique aqui</Link>
              </span>
            )}
          </small>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Card.Title>Protocolo</Card.Title>
          <Card.Text>
            Acesse o material que define o protocolo de atendimento adotado por
            nossa plataforma.
          </Card.Text>
          <a
            href="https://centralcorona.com/protocolo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="primary">Acessar Protocolo</Button>
          </a>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Card.Title>Submissão de casos suspeitos</Card.Title>
          <Card.Text>
            As notificações de novos casos suspeitos poderão ser realizadas na
            nova plataforma do Governo Federal.
          </Card.Text>
          <a
            href="https://notifica.saude.gov.br/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="primary">Plataforma de notificação</Button>
          </a>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Card.Title>Suporte em tempo real</Card.Title>
          <Card.Text>
            Em caso de dúvidas, entre em contato com nossa central via WhatsApp.
          </Card.Text>
          <a
            href="https://wa.me/551123910696"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="primary">Contactar Suporte</Button>
          </a>
        </Card.Body>
      </Card>
      <br />
    </Jumbotron>
  );
}

export default Voluntario;
