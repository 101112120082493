import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/App.css";
import Home from "./Home";
import Header from "./Header";
import Atendimento from "./Atendimento";
import Voluntario from "./Voluntario";
// import Faq from "./Faq";
import Inbox from "./Inbox";
import Cadastro from "./Cadastro";
import AdminPanel from "./Admin-panel";
import AdminLogin from "./Admin-login";
import Login from "./Login";
import Painel from "./Painel";
import Footer from "./Footer";
import Equipe from "./Equipe";
import Review from "./Review";
import End from "./End"

import { Switch, Route } from "react-router-dom";

const Deck = () => {
  window.location.replace(
    "https://docs.google.com/presentation/d/e/2PACX-1vSV3NDTgw15c9HvRrhASJE10hyYHRbQF0hgmRryoEx1jSCrVRTfUhTJGLAPj175QNplszC2Nrut84pp/pub?start=false&loop=false&delayms=10000&slide=id.p"
  );
};

const Protocolo = () => {
  window.location.replace(
    "https://www.figma.com/proto/MGod3BVipy5nlXNzHpva1K/Protocolo?node-id=1%3A6&viewport=668%2C773%2C0.7566478848457336&scaling=min-zoom"
  );
};

const Faq = () => {
  window.location.replace("https://suporte.centralcorona.com/hc/pt-br");
};

const FaqCorona = () => {
  window.location.replace(
    "https://suporte.centralcorona.com/hc/pt-br/sections/360002184798-Sobre-o-Coronav%C3%ADrus"
  );
};

const NotFound = () => {
  return <h2 style={{ marginTop: "50px" }}>Página não encontrada</h2>;
};

function App() {
  return (
    <>
      <div className="App">
        <Header />
        <Switch>
          <Route exact path="/" component={End} />
          <Route
            exact
            path="/atendimento-coronavirus"
            component={End}
          />
          <Route exact path="/faq" component={End} />
          <Route exact path="/faq-corona" component={End} />
          <Route exact path="/voluntario" component={End} />
          <Route exact path="/cadastro" component={End} />
          <Route exact path="/admin-panel" component={End} />
          <Route exact path="/admin" component={End} />
          <Route exact path="/inbox" component={End} />
          <Route exact path="/login" component={End} />
          <Route exact path="/painel" component={End} />
          <Route exact path="/equipe" component={End} />
          <Route exact path="/deck" component={End} />
          <Route exact path="/protocolo" component={End} />
          <Route exact path="/review/*" component={End} />

          <Route component={End} />
        </Switch>
        <Footer />
      </div>
    </>
  );
}

export default App;
