import React from "react";
import Lottie from "react-lottie";

export default class Icon extends React.Component {
  render() {
    return (
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: {
            v: "5.2.1",
            fr: 30,
            ip: 0,
            op: 180,
            w: 1920,
            h: 1080,
            nm: "working black",
            ddd: 0,
            assets: [
              {
                id: "comp_0",
                layers: [
                  {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: "Shape Layer 1",
                    td: 1,
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [131.629, 62.041, 0], ix: 2 },
                      a: { a: 0, k: [4.129, 12.041, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [145.258, 70.082], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "st",
                            c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 8, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            ml2: { a: 0, k: 4, ix: 8 },
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: true
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.117647066303,
                                0.117647066303,
                                0.117647066303,
                                1
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [4.129, 12.041], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle 1",
                        np: 3,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 2,
                    ty: 0,
                    nm: "data",
                    tt: 1,
                    refId: "comp_1",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.667, y: 1 },
                            o: { x: 0.333, y: 0 },
                            n: "0p667_1_0p333_0",
                            t: 0,
                            s: [127.5, 254, 0],
                            e: [127.5, 193, 0],
                            to: [0, -10.1666669845581, 0],
                            ti: [0, 19.1666660308838, 0]
                          },
                          {
                            i: { x: 0.667, y: 1 },
                            o: { x: 0.333, y: 0 },
                            n: "0p667_1_0p333_0",
                            t: 26.667,
                            s: [127.5, 193, 0],
                            e: [127.5, 139, 0],
                            to: [0, -19.1666660308838, 0],
                            ti: [0, 18.8333339691162, 0]
                          },
                          {
                            i: { x: 0.667, y: 1 },
                            o: { x: 0.333, y: 0 },
                            n: "0p667_1_0p333_0",
                            t: 53.333,
                            s: [127.5, 139, 0],
                            e: [127.5, 80, 0],
                            to: [0, -18.8333339691162, 0],
                            ti: [0, 19, 0]
                          },
                          {
                            i: { x: 0.667, y: 1 },
                            o: { x: 0.333, y: 0 },
                            n: "0p667_1_0p333_0",
                            t: 80,
                            s: [127.5, 80, 0],
                            e: [127.5, 25, 0],
                            to: [0, -19, 0],
                            ti: [0, 18.8333339691162, 0]
                          },
                          {
                            i: { x: 0.667, y: 1 },
                            o: { x: 0.333, y: 0 },
                            n: "0p667_1_0p333_0",
                            t: 106.667,
                            s: [127.5, 25, 0],
                            e: [127.5, -33, 0],
                            to: [0, -18.8333339691162, 0],
                            ti: [0, 19.5, 0]
                          },
                          {
                            i: { x: 0.667, y: 1 },
                            o: { x: 0.333, y: 0 },
                            n: "0p667_1_0p333_0",
                            t: 133.333,
                            s: [127.5, -33, 0],
                            e: [127.5, -92, 0],
                            to: [0, -19.5, 0],
                            ti: [0, 20, 0]
                          },
                          {
                            i: { x: 0.667, y: 1 },
                            o: { x: 0.167, y: 0 },
                            n: "0p667_1_0p167_0",
                            t: 160,
                            s: [127.5, -92, 0],
                            e: [127.5, -153, 0],
                            to: [0, -20, 0],
                            ti: [0, 10.1666669845581, 0]
                          },
                          { t: 180 }
                        ],
                        ix: 2
                      },
                      a: { a: 0, k: [68, 220, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    w: 136,
                    h: 440,
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: "Path 3",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [241.474, 49.607, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [1.99, -1.53],
                                  [0, 0],
                                  [0, 2.97],
                                  [0, 0],
                                  [-2.39, -1.47],
                                  [0, 0]
                                ],
                                o: [
                                  [0, 0],
                                  [-2.37, 1.8],
                                  [0, 0],
                                  [0, -2.81],
                                  [0, 0],
                                  [2.14, 1.32]
                                ],
                                v: [
                                  [7.393, 2.084],
                                  [-3.027, 10.054],
                                  [-8.797, 7.204],
                                  [-8.797, -7.206],
                                  [-3.327, -10.256],
                                  [7.093, -3.816]
                                ],
                                c: true
                              },
                              ix: 2
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.97647100687,
                                0.97647100687,
                                0.97647100687,
                                1
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: "Rectangle 4",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [34.226, 55.1, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [16.737, 16.737], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 8.368, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.827450990677,
                                0.827450990677,
                                0.827450990677,
                                1
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: "Rectangle 3",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [34.226, 40.753, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [9.564, 9.564], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 4.782, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                1
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: "Rectangle 2",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [34.823, 49.721, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [39.451, 39.451], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 19.726, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.847059011459,
                                0.823529005051,
                                0.823529005051,
                                0.300000011921
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 30, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: "Path 2",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [120.599, 49.721, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [26.08, 0],
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [0, 23.77],
                                  [0, 0],
                                  [-26.08, 0],
                                  [0, 0],
                                  [0, -26.08]
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [-23.77, 0],
                                  [0, 0],
                                  [0, -26.08],
                                  [0, 0],
                                  [26.08, 0],
                                  [0, 26.08]
                                ],
                                v: [
                                  [70.233, 47.219],
                                  [-33.177, 47.219],
                                  [-45.727, 47.219],
                                  [-74.417, 47.219],
                                  [-117.457, 4.189],
                                  [-117.457, -0.001],
                                  [-70.237, -47.221],
                                  [70.233, -47.221],
                                  [117.453, -0.001]
                                ],
                                c: true
                              },
                              ix: 2
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.97647100687,
                                0.97647100687,
                                0.97647100687,
                                1
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  }
                ]
              },
              {
                id: "comp_1",
                layers: [
                  {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: "Rectangle 20",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [69.167, 417.342, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [121.94, 7.173], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 3.586, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                0.419999986887
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 42, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: "Rectangle 19",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [47.051, 430.493, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [77.707, 7.173], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 3.586, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                0.419999986887
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 42, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: "Rectangle 18",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [47.051, 369.993, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [77.707, 7.173], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 3.586, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                0.419999986887
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 42, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: "Rectangle 17",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [69.167, 356.842, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [121.94, 7.173], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 3.586, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                0.419999986887
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 42, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: "Rectangle 16",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [69.167, 299.342, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [121.94, 7.173], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 3.586, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                0.419999986887
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 42, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: "Rectangle 15",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [47.051, 312.493, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [77.707, 7.173], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 3.586, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                0.419999986887
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 42, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: "Rectangle 14",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [47.051, 251.993, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [77.707, 7.173], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 3.586, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                0.419999986887
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 42, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 8,
                    ty: 4,
                    nm: "Rectangle 13",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [69.167, 238.842, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [121.94, 7.173], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 3.586, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                0.419999986887
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 42, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 9,
                    ty: 4,
                    nm: "Rectangle 12",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [69.167, 185.342, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [121.94, 7.173], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 3.586, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                0.419999986887
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 42, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 10,
                    ty: 4,
                    nm: "Rectangle 11",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [47.051, 198.493, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [77.707, 7.173], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 3.586, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                0.419999986887
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 42, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 11,
                    ty: 4,
                    nm: "Rectangle 10",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [47.051, 137.993, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [77.707, 7.173], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 3.586, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                0.419999986887
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 42, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 12,
                    ty: 4,
                    nm: "Rectangle 9",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [69.167, 124.842, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [121.94, 7.173], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 3.586, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                0.419999986887
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 42, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 13,
                    ty: 4,
                    nm: "Rectangle 8",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [69.167, 70.842, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [121.94, 7.173], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 3.586, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                0.419999986887
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 42, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 14,
                    ty: 4,
                    nm: "Rectangle 7",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [47.051, 83.993, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [77.707, 7.173], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 3.586, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                0.419999986887
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 42, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 15,
                    ty: 4,
                    nm: "Rectangle 22",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [47.051, 23.493, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [77.707, 7.173], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 3.586, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                0.419999986887
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 42, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 16,
                    ty: 4,
                    nm: "Rectangle 21",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [69.167, 10.342, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [121.94, 7.173], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 3.586, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                0.419999986887
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 42, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  }
                ]
              },
              {
                id: "comp_2",
                layers: [
                  {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: "Oval 4",
                    sr: 1,
                    ks: {
                      o: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 15,
                            s: [100],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 16,
                            s: [0],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 39,
                            s: [0],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 40,
                            s: [100],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 45,
                            s: [100],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 46,
                            s: [0],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 69,
                            s: [0],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 70,
                            s: [100],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 75,
                            s: [100],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 76,
                            s: [0],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 99,
                            s: [0],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 100,
                            s: [100],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 105,
                            s: [100],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 106,
                            s: [0],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 129,
                            s: [0],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 130,
                            s: [100],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 135,
                            s: [100],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 136,
                            s: [0],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 159,
                            s: [0],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 160,
                            s: [100],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 165,
                            s: [100],
                            e: [0]
                          },
                          { t: 166 }
                        ],
                        ix: 11
                      },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [120.076, 51.82, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            d: 1,
                            ty: "el",
                            s: { a: 0, k: [7.105, 7.105], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: "Ellipse Path 1",
                            mn: "ADBE Vector Shape - Ellipse",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                1
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Oval",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 10,
                    op: 190,
                    st: 10,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: "Oval 3",
                    sr: 1,
                    ks: {
                      o: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 15,
                            s: [100],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 16,
                            s: [0],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 34,
                            s: [0],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 35,
                            s: [100],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 45,
                            s: [100],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 46,
                            s: [0],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 64,
                            s: [0],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 65,
                            s: [100],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 75,
                            s: [100],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 76,
                            s: [0],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 94,
                            s: [0],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 95,
                            s: [100],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 105,
                            s: [100],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 106,
                            s: [0],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 124,
                            s: [0],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 125,
                            s: [100],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 135,
                            s: [100],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 136,
                            s: [0],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 154,
                            s: [0],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 155,
                            s: [100],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 165,
                            s: [100],
                            e: [0]
                          },
                          { t: 166 }
                        ],
                        ix: 11
                      },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [105.866, 51.82, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            d: 1,
                            ty: "el",
                            s: { a: 0, k: [7.105, 7.105], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: "Ellipse Path 1",
                            mn: "ADBE Vector Shape - Ellipse",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                1
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Oval",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 5,
                    op: 185,
                    st: 5,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: "Oval 2",
                    sr: 1,
                    ks: {
                      o: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 15,
                            s: [100],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 16,
                            s: [0],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 29,
                            s: [0],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 30,
                            s: [100],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 45,
                            s: [100],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 46,
                            s: [0],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 59,
                            s: [0],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 60,
                            s: [100],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 75,
                            s: [100],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 76,
                            s: [0],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 89,
                            s: [0],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 90,
                            s: [100],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 105,
                            s: [100],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 106,
                            s: [0],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 119,
                            s: [0],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 120,
                            s: [100],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 135,
                            s: [100],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 136,
                            s: [0],
                            e: [0]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 149,
                            s: [0],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 150,
                            s: [100],
                            e: [100]
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            n: ["0p833_0p833_0p167_0p167"],
                            t: 165,
                            s: [100],
                            e: [0]
                          },
                          { t: 166 }
                        ],
                        ix: 11
                      },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [91.659, 51.82, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            d: 1,
                            ty: "el",
                            s: { a: 0, k: [7.105, 7.105], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: "Ellipse Path 1",
                            mn: "ADBE Vector Shape - Ellipse",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                1
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Oval",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: "Path 3",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [64.735, 104.083, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [1.53, 1.99],
                                  [0, 0],
                                  [-2.97, 0],
                                  [0, 0],
                                  [1.48, -2.39],
                                  [0, 0]
                                ],
                                o: [
                                  [0, 0],
                                  [-1.8, -2.36],
                                  [0, 0],
                                  [2.81, 0],
                                  [0, 0],
                                  [-1.32, 2.14]
                                ],
                                v: [
                                  [-2.085, 7.393],
                                  [-10.055, -3.027],
                                  [-7.205, -8.797],
                                  [7.205, -8.797],
                                  [10.255, -3.327],
                                  [3.815, 7.093]
                                ],
                                c: true
                              },
                              ix: 2
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.97647100687,
                                0.97647100687,
                                0.97647100687,
                                1
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: "Rectangle 5",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [105.866, 51.821, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [62.166, 26.642], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 13.321, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.949020028114,
                                0.949020028114,
                                0.949020028114,
                                1
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: "Rectangle 4",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [36.529, 57.626, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [16.737, 16.737], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 8.368, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.827450990677,
                                0.827450990677,
                                0.827450990677,
                                1
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: "Rectangle 3",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [36.529, 43.279, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [9.564, 9.564], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 4.782, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.768626987934,
                                0.768626987934,
                                0.768626987934,
                                1
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 8,
                    ty: 4,
                    nm: "Rectangle 2",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [37.127, 52.247, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [39.451, 39.451], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 19.726, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.847059011459,
                                0.823529005051,
                                0.823529005051,
                                0.300000011921
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 30, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 9,
                    ty: 4,
                    nm: "Path 2",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [122.901, 52.247, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [26.08, 0],
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [0, 23.76],
                                  [0, 0],
                                  [-26.08, 0],
                                  [0, 0],
                                  [0, -26.08]
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [-23.77, 0],
                                  [0, 0],
                                  [0, -26.08],
                                  [0, 0],
                                  [26.08, 0],
                                  [0, 26.08]
                                ],
                                v: [
                                  [70.233, 47.219],
                                  [-33.177, 47.219],
                                  [-45.727, 47.219],
                                  [-74.417, 47.219],
                                  [-117.457, 4.189],
                                  [-117.457, -0.001],
                                  [-70.237, -47.221],
                                  [70.233, -47.221],
                                  [117.453, -0.001]
                                ],
                                c: true
                              },
                              ix: 2
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.97647100687,
                                0.97647100687,
                                0.97647100687,
                                1
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  }
                ]
              },
              {
                id: "comp_3",
                layers: [
                  {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: "Path",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [20.586, 22.923, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0, 0],
                                  [-8.97, 0]
                                ],
                                o: [
                                  [10.76, 1.2],
                                  [0, 0]
                                ],
                                v: [
                                  [-14.944, -0.6],
                                  [14.946, 0.6]
                                ],
                                c: false
                              },
                              ix: 2
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false
                          },
                          {
                            ty: "st",
                            c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 1.195, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            ml2: { a: 0, k: 4, ix: 8 },
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: "Path",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [37.322, 27.704, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0, 0],
                                  [-9.09, -3.83]
                                ],
                                o: [
                                  [17.34, 2.99],
                                  [0, 0]
                                ],
                                v: [
                                  [-31.083, -1.793],
                                  [31.087, 1.797]
                                ],
                                c: false
                              },
                              ix: 2
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false
                          },
                          {
                            ty: "st",
                            c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 1.195, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            ml2: { a: 0, k: 4, ix: 8 },
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: "Shape 2",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [18.802, 20.143, 0], ix: 2 },
                      a: { a: 0, k: [109, 268, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [5.82, 1.79],
                                  [0, 0],
                                  [-10.56, 3.14],
                                  [-18.68, -4.18],
                                  [0, 0]
                                ],
                                o: [
                                  [-10.26, -3.16],
                                  [10.98, 6.25],
                                  [0, 0],
                                  [4.18, 0.94],
                                  [0, 0]
                                ],
                                v: [
                                  [99.58, 276.754],
                                  [99.58, 251.054],
                                  [127.08, 253.444],
                                  [159.201, 268.984],
                                  [159.201, 278.544]
                                ],
                                c: true
                              },
                              ix: 2
                            },
                            nm: "Path 3",
                            mn: "ADBE Vector Shape - Group",
                            hd: false
                          },
                          {
                            ty: "mm",
                            mm: 1,
                            nm: "Merge Paths 1",
                            mn: "ADBE Vector Filter - Merge",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.137254998088,
                                0.160784006119,
                                0.725489974022,
                                1
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Shape",
                        np: 3,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  }
                ]
              },
              {
                id: "comp_4",
                layers: [
                  {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: "Path",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [20.392, 22.997, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0, 0],
                                  [-10.16, -2.39]
                                ],
                                o: [
                                  [17.53, 3.59],
                                  [0, 0]
                                ],
                                v: [
                                  [-15.841, -3.886],
                                  [15.839, 3.884]
                                ],
                                c: false
                              },
                              ix: 2
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false
                          },
                          {
                            ty: "st",
                            c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 1.195, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            ml2: { a: 0, k: 4, ix: 8 },
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: "Path",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [35.335, 30.468, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0, 0],
                                  [-19.73, -3.59]
                                ],
                                o: [
                                  [17.53, 3.59],
                                  [0, 0]
                                ],
                                v: [
                                  [-31.979, -7.771],
                                  [31.981, 7.769]
                                ],
                                c: false
                              },
                              ix: 2
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false
                          },
                          {
                            ty: "st",
                            c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 1.195, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            ml2: { a: 0, k: 4, ix: 8 },
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  },
                  {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: "Shape 3",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [16.177, 19.143, 0], ix: 2 },
                      a: { a: 0, k: [19, 247.5, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 }
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [5.25, 0],
                                  [0, 0],
                                  [-10.35, 2.22],
                                  [-16.77, -10.63],
                                  [0, 0]
                                ],
                                o: [
                                  [-10.74, 0],
                                  [10.37, 9.39],
                                  [0, 0],
                                  [5.92, 3.76],
                                  [0, 0]
                                ],
                                v: [
                                  [10.245, 256.525],
                                  [15.495, 229.535],
                                  [42.025, 237.125],
                                  [71.985, 260.015],
                                  [71.985, 270.965]
                                ],
                                c: true
                              },
                              ix: 2
                            },
                            nm: "Path 2",
                            mn: "ADBE Vector Shape - Group",
                            hd: false
                          },
                          {
                            ty: "mm",
                            mm: 1,
                            nm: "Merge Paths 1",
                            mn: "ADBE Vector Filter - Merge",
                            hd: false
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.137254998088,
                                0.160784006119,
                                0.725489974022,
                                1
                              ],
                              ix: 4
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform"
                          }
                        ],
                        nm: "Shape",
                        np: 3,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false
                      }
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0
                  }
                ]
              }
            ],
            layers: [
              {
                ddd: 0,
                ind: 1,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [690.074, 623.903, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0.32, -4.19],
                              [0, 0],
                              [-1.82, -1.93],
                              [-62.05, 2.02],
                              [-1.52, 8.67],
                              [4.81, -1.16],
                              [0, 0],
                              [0.85, 0.01],
                              [7.09, 105.48],
                              [0.94, 0.86]
                            ],
                            o: [
                              [0, 0],
                              [-0.2, 2.65],
                              [9.28, 9.92],
                              [48.09, -1.56],
                              [0.85, -4.88],
                              [0, 0],
                              [-0.83, 0.2],
                              [-123.65, -0.81],
                              [-0.09, -1.27],
                              [-3.09, -2.85]
                            ],
                            v: [
                              [-68.879, -116.289],
                              [-83.759, 75.491],
                              [-81.379, 82.681],
                              [23.501, 120.841],
                              [83.701, 97.991],
                              [74.491, 91.741],
                              [24.741, 103.801],
                              [22.161, 104.101],
                              [-58.849, -116.279],
                              [-60.459, -119.599]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.01568627451, 0.01568627451, 0.01568627451, 1],
                          ix: 4
                        },
                        o: { a: 0, k: 6, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 2,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [708.885, 712.282, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-0.2, 2.64],
                              [0, 0],
                              [-2.42, 1.56],
                              [-1.02, -6.87],
                              [-120.43, -16.87],
                              [0, -5.5],
                              [0, 0],
                              [1.22, -1.56],
                              [53.92, -5.04],
                              [0, 0],
                              [0, 0],
                              [0, -6.6],
                              [0, 0],
                              [6.6, 0],
                              [0, 0],
                              [0, 6.6],
                              [0, 0],
                              [-6.6, 0],
                              [0, 0],
                              [0, 0],
                              [8.88, 9.49]
                            ],
                            o: [
                              [0, 0],
                              [0.22, -2.87],
                              [5.84, -3.76],
                              [8.22, 55.33],
                              [5.45, 0.76],
                              [0, 0],
                              [0, 1.99],
                              [-6.23, 7.98],
                              [0, 0],
                              [0, 0],
                              [6.6, 0],
                              [0, 0],
                              [0, 6.6],
                              [0, 0],
                              [-6.6, 0],
                              [0, 0],
                              [0, -6.6],
                              [0, 0],
                              [0, 0],
                              [-57.02, -0.65],
                              [-1.82, -1.93]
                            ],
                            v: [
                              [-102.568, -12.888],
                              [-87.238, -210.448],
                              [-83.038, -217.478],
                              [-68.388, -210.568],
                              [92.942, -54.798],
                              [102.602, -43.938],
                              [102.602, -11.818],
                              [100.832, -6.328],
                              [17.122, 31.682],
                              [17.122, 192.662],
                              [51.792, 192.662],
                              [63.742, 204.612],
                              [63.742, 207.002],
                              [51.792, 218.962],
                              [-40.268, 218.962],
                              [-52.218, 207.002],
                              [-52.218, 204.612],
                              [-40.268, 192.662],
                              [-3.198, 192.662],
                              [-3.198, 32.552],
                              [-100.188, -5.698]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.780391991138, 0.882353007793, 1, 1],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 3,
                ty: 4,
                nm: "Shape",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [810.802, 645.518, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-0.05, 0.28],
                              [0, 32.16],
                              [0, 0],
                              [-18.53, -5.23],
                              [0, 0],
                              [11.68, 5.57],
                              [0, 0],
                              [34.08, 2.98],
                              [5.49, -199.18],
                              [0, 0],
                              [-27.5, 3.189],
                              [0, 0]
                            ],
                            o: [
                              [0.13, -0.26],
                              [0, 0],
                              [0, -32.17],
                              [0, 0],
                              [18.54, 5.24],
                              [3.59, 0],
                              [0, 0],
                              [0, 0],
                              [-8.36, 0],
                              [0, 0],
                              [0, 0],
                              [27.5, -3.189],
                              [0, 0]
                            ],
                            v: [
                              [-43.081, -72.329],
                              [-42.811, -73.139],
                              [-49.261, -118.999],
                              [-56.441, -186.899],
                              [-8.731, -153.469],
                              [113.959, -151.169],
                              [111.569, -167.839],
                              [4.549, -186.899],
                              [-106.781, -278.549],
                              [-159.971, -72.329],
                              [-160.111, -72.329],
                              [-107.302, -63.829],
                              [-35.181, -72.329]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.33725490196078434, 0.3764705882352941, 1],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Shape",
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 4,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1119.361, 899.167, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [14.39, 0],
                              [3.53, 26.79],
                              [0, 0]
                            ],
                            o: [
                              [13.22, -13.44],
                              [0, 0],
                              [4.05, 38.68]
                            ],
                            v: [
                              [-16.659, 24.964],
                              [-2.269, -24.966],
                              [16.181, -24.966]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.101960784314,
                            0.101960784314,
                            0.101960784314,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 7, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 5,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1093.191, 899.39, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [-58.03, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [44.98, 0]
                            ],
                            v: [
                              [42.692, -25.64],
                              [-42.328, -25.64],
                              [4.912, 25.64]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.780391991138, 0.882353007793, 1, 1],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 6,
                ty: 4,
                nm: "Shape",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1141.182, 795.932, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-9.49, 13.69]
                            ],
                            o: [
                              [7.38, -10.16],
                              [0, 0]
                            ],
                            v: [
                              [-49.724, 81.874],
                              [-23.884, 45.434]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [21.25, -33.26]
                            ],
                            o: [
                              [0.91, 9.67],
                              [0, 0]
                            ],
                            v: [
                              [49.691, -81.87],
                              [12.511, -9]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 2",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 2,
                        ty: "sh",
                        ix: 3,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-23.03, -10.44]
                            ],
                            o: [
                              [6.23, -7.05],
                              [0, 0]
                            ],
                            v: [
                              [-23.885, 45.435],
                              [23.595, 37.335]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 3",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 3,
                        ty: "sh",
                        ix: 4,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-11.55, 18.07]
                            ],
                            o: [
                              [12, -17.3],
                              [0, 0]
                            ],
                            v: [
                              [-23.885, 45.433],
                              [12.515, -8.997]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 4",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 4,
                        ty: "sh",
                        ix: 5,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [10.44, 18.35]
                            ],
                            o: [
                              [3.84, -13.05],
                              [0, 0]
                            ],
                            v: [
                              [12.514, -8.995],
                              [11.004, -71.074]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 5",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false
                      },
                      {
                        ty: "st",
                        c: { a: 0, k: [1, 1, 1, 0.119999997318], ix: 3 },
                        o: { a: 0, k: 12, ix: 4 },
                        w: { a: 0, k: 1.799, ix: 5 },
                        lc: 2,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Shape",
                    np: 7,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 7,
                ty: 4,
                nm: "Shape",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1049.848, 822.021, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [6.36, 9.19]
                            ],
                            o: [
                              [-4.89, -6.72],
                              [0, 0]
                            ],
                            v: [
                              [34.412, 57.128],
                              [17.192, 32.838]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-14.33, -22.64]
                            ],
                            o: [
                              [0.54, 8.67],
                              [0, 0]
                            ],
                            v: [
                              [-34.412, -57.129],
                              [-7.872, -4.859]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 2",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 2,
                        ty: "sh",
                        ix: 3,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-8.64, 12.24]
                            ],
                            o: [
                              [-3, -9.18],
                              [0, 0]
                            ],
                            v: [
                              [-7.868, -4.854],
                              [-6.068, -47.684]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 3",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 3,
                        ty: "sh",
                        ix: 4,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-8.2, -11.85]
                            ],
                            o: [
                              [8.01, 12.66],
                              [0, 0]
                            ],
                            v: [
                              [-7.871, -4.857],
                              [17.189, 32.833]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 4",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 4,
                        ty: "sh",
                        ix: 5,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [15.83, -7.56]
                            ],
                            o: [
                              [-4.61, -4.8],
                              [0, 0]
                            ],
                            v: [
                              [17.192, 32.839],
                              [-16.418, 27.889]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 5",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false
                      },
                      {
                        ty: "st",
                        c: { a: 0, k: [1, 1, 1, 0.119999997318], ix: 3 },
                        o: { a: 0, k: 12, ix: 4 },
                        w: { a: 0, k: 1.799, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Shape",
                    np: 7,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 8,
                ty: 4,
                nm: "Shape",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1073.779, 738.576, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [20.51, 32.75],
                              [-38.15, 36.35]
                            ],
                            o: [
                              [10.8, -16.94],
                              [-11.84, -15.6],
                              [0, 0]
                            ],
                            v: [
                              [1.481, 138.322],
                              [8.231, 46.561],
                              [20.381, -45.208]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [9.36, 14.03]
                            ],
                            o: [
                              [13.49, -16.19],
                              [0, 0]
                            ],
                            v: [
                              [20.378, -45.213],
                              [33.868, -113.133]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 2",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 2,
                        ty: "sh",
                        ix: 3,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [15.3, 14.4]
                            ],
                            o: [
                              [3.15, -29.23],
                              [0, 0]
                            ],
                            v: [
                              [20.374, -45.213],
                              [1.034, -115.833]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 3",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 3,
                        ty: "sh",
                        ix: 4,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [13.11, 16.11]
                            ],
                            o: [
                              [-11.06, -10.69],
                              [0, 0]
                            ],
                            v: [
                              [20.375, -45.212],
                              [-21.965, -91.092]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 4",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 4,
                        ty: "sh",
                        ix: 5,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-8.22, -10.09]
                            ],
                            o: [
                              [1.25, 5.55],
                              [0, 0]
                            ],
                            v: [
                              [-37.651, -115.833],
                              [-21.961, -91.093]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 5",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 5,
                        ty: "sh",
                        ix: 6,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [17.63, 20.51]
                            ],
                            o: [
                              [5.12, -7.19],
                              [0, 0]
                            ],
                            v: [
                              [-21.964, -91.094],
                              [-28.654, -138.324]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 6",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false
                      },
                      {
                        ty: "st",
                        c: { a: 0, k: [1, 1, 1, 0.119999997318], ix: 3 },
                        o: { a: 0, k: 12, ix: 4 },
                        w: { a: 0, k: 1.799, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Shape",
                    np: 8,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 9,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1168.791, 765.569, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [14.75, -44.86],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [34.11, 23.61],
                              [8.49, 5.73],
                              [42.3, 26.07],
                              [7.79, 5.24],
                              [23.58, 0.68],
                              [17.92, -1.86],
                              [13.98, 1.17]
                            ],
                            o: [
                              [-11.6, 35.3],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-8.42, -5.83],
                              [-41.18, -27.81],
                              [-8, -4.93],
                              [-19.58, -13.16],
                              [-18.01, -0.52],
                              [-13.95, 1.45],
                              [-47.05, -3.95]
                            ],
                            v: [
                              [-44.499, 26.773],
                              [-88.129, 111.333],
                              [-78.009, 111.333],
                              [-67.889, 111.333],
                              [27.031, 63.653],
                              [2.741, 53.763],
                              [81.011, -46.557],
                              [58.521, -55.097],
                              [36.471, -111.327],
                              [2.741, -79.847],
                              [-30.549, -93.337]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.192157000303,
                            0.407842993736,
                            0.568626999855,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 10,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1031.179, 802.593, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-10.24, -31.14],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-23.68, 16.39],
                              [-5.89, 3.98],
                              [-29.36, 18.09],
                              [-5.42, 3.64],
                              [-16.37, 0.48],
                              [-12.45, -1.29],
                              [-9.71, 0.82]
                            ],
                            o: [
                              [8.05, 24.51],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [5.84, -4.04],
                              [28.58, -19.31],
                              [5.56, -3.42],
                              [13.58, -9.14],
                              [12.5, -0.36],
                              [9.68, 1],
                              [32.66, -2.74]
                            ],
                            v: [
                              [30.892, 18.582],
                              [61.182, 77.292],
                              [54.152, 77.292],
                              [47.122, 77.292],
                              [-18.758, 44.182],
                              [-1.898, 37.322],
                              [-56.238, -32.318],
                              [-40.618, -38.248],
                              [-25.318, -77.288],
                              [2.162, -46.698],
                              [21.212, -64.798]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.207843005657,
                            0.505882024765,
                            0.674510002136,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 11,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1075.268, 715.195, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0.33, 15.37],
                              [-10.77, 30.61],
                              [-6.02, 11.6],
                              [-25.53, 47.61],
                              [-21.98, -0.86],
                              [-26.27, -0.03],
                              [-9.49, -16.34],
                              [0, 0],
                              [-2.33, -29.9],
                              [0.95, -24.38],
                              [-1.54, -13.42],
                              [-1.94, -9.49],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [-0.69, -32.44],
                              [4.34, -12.33],
                              [24.9, -47.94],
                              [10.4, -19.39],
                              [26.25, 1.03],
                              [21.66, 0.03],
                              [0, 0],
                              [9.48, 16.34],
                              [1.88, 24.34],
                              [-0.53, 13.51],
                              [1.1, 9.63],
                              [6.72, 33.08],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-9.449, 161.723],
                              [-15.539, 115.843],
                              [-52.869, 41.623],
                              [-33.079, 14.633],
                              [-61.419, -120.767],
                              [-22.729, -161.707],
                              [-0.239, -100.817],
                              [40.701, -141.907],
                              [64.991, -100.077],
                              [40.701, -27.207],
                              [64.991, 30.373],
                              [55.541, 63.663],
                              [64.991, 86.603],
                              [9.891, 161.723],
                              [-0.239, 161.723]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.274509996176, 0.44313699007, 0.643136978149, 1],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 12,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1113.471, 712.88, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0.76, 0.67],
                              [0, 0],
                              [-58.6, -16.36],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [75.16, 20.99],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-0.57, -0.84]
                            ],
                            v: [
                              [-11.318, -167.594],
                              [-60.978, -210.814],
                              [35.882, -201.634],
                              [-6.558, 212.386],
                              [-60.978, 212.386],
                              [-2.398, -155.224],
                              [-9.308, -165.324]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.015685999627,
                            0.015685999627,
                            0.015685999627,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 6, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 13,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1019.562, 713.065, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [1.46, 5.15],
                              [0, 0],
                              [-7.4, 0.67],
                              [-49.05, -19.86],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [-2.02, -7.15],
                              [50.77, -4.62],
                              [72.32, 29.3],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-5.35, 0]
                            ],
                            v: [
                              [-146.448, -164.11],
                              [-153.248, -188.11],
                              [-143.028, -203.15],
                              [129.792, -201.82],
                              [87.352, 212.2],
                              [-13.068, 212.2],
                              [-48.798, -155.41],
                              [-134.938, -155.41]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 14,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1111.299, 444.422, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-6.622, 4.794],
                              [-3.273, -4.796],
                              [6.617, -4.796],
                              [2.208, 4.794]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.101960784314,
                            0.101960784314,
                            0.101960784314,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 7, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 15,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1099.654, 444.422, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [8.372, -4.796],
                              [-18.268, -4.796],
                              [-13.398, 4.794],
                              [13.852, 4.794],
                              [18.272, -4.796]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.780391991138, 0.882353007793, 1, 1],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 16,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1088.233, 426.295, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-15.06, -7.12],
                              [6.48, 3.93]
                            ],
                            o: [
                              [-3.73, -10.27],
                              [0.15, 8.94]
                            ],
                            v: [
                              [9.584, 17.913],
                              [-9.586, -17.917]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.050980001688,
                            0.050980001688,
                            0.050980001688,
                            0.119999997318
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 12, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 17,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1088.389, 426.238, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-15.15, -7.49],
                              [18.44, 6.76]
                            ],
                            o: [
                              [1.3, -9.17],
                              [0.08, 8.86]
                            ],
                            v: [
                              [9.427, 17.976],
                              [-9.743, -17.974]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.227450996637,
                            0.568626999855,
                            0.760783970356,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 18,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1110.157, 419.986, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [20.1, -9.5],
                              [-8.64, 5.24]
                            ],
                            o: [
                              [4.97, -13.7],
                              [-0.2, 11.93]
                            ],
                            v: [
                              [-12.788, 23.9],
                              [12.783, -23.9]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.050980003207,
                            0.050980003207,
                            0.050980003207,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 12, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 19,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1109.948, 419.909, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [20.22, -9.98],
                              [-24.6, 9.01]
                            ],
                            o: [
                              [-1.73, -12.23],
                              [-0.1, 11.82]
                            ],
                            v: [
                              [-12.577, 23.973],
                              [12.993, -23.977]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.211765006185,
                            0.458824008703,
                            0.639216005802,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 20,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1100.698, 405.152, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [14.96, -21.62],
                              [-5.76, 10.48]
                            ],
                            o: [
                              [-3.01, -16.98],
                              [6.85, 12.34]
                            ],
                            v: [
                              [-1.476, 32.052],
                              [-3.556, -32.048]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.050980001688,
                            0.050980001688,
                            0.050980001688,
                            0.119999997318
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 12, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 21,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1096.742, 405.073, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [14.8, -22.19],
                              [-19.87, 23.78]
                            ],
                            o: [
                              [-9, -11.5],
                              [6.89, 12.17]
                            ],
                            v: [
                              [2.476, 32.123],
                              [0.316, -32.127]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.274509996176, 0.44313699007, 0.643136978149, 1],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 22,
                ty: 0,
                nm: "not1",
                refId: "comp_0",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1190, 195, 0], ix: 2 },
                  a: { a: 0, k: [127.5, 50, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                        o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                        n: ["0p667_1_1_0", "0p667_1_1_0", "0p667_1_0p333_0"],
                        t: 0,
                        s: [0, 0, 100],
                        e: [100, 100, 100]
                      },
                      {
                        i: { x: [0.789, 0.789, 0.789], y: [1, 1, 1] },
                        o: { x: [0.321, 0.321, 0.321], y: [0, 0, 0] },
                        n: [
                          "0p789_1_0p321_0",
                          "0p789_1_0p321_0",
                          "0p789_1_0p321_0"
                        ],
                        t: 8,
                        s: [100, 100, 100],
                        e: [100, 100, 100]
                      },
                      {
                        i: { x: [0.712, 0.712, 0.712], y: [1, 1, 1] },
                        o: { x: [0.377, 0.377, 0.377], y: [0, 0, 0] },
                        n: [
                          "0p712_1_0p377_0",
                          "0p712_1_0p377_0",
                          "0p712_1_0p377_0"
                        ],
                        t: 166,
                        s: [100, 100, 100],
                        e: [0, 0, 100]
                      },
                      { t: 174 }
                    ],
                    ix: 6
                  }
                },
                ao: 0,
                w: 255,
                h: 100,
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 23,
                ty: 0,
                nm: "not2",
                refId: "comp_2",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1047, 326, 0], ix: 2 },
                  a: { a: 0, k: [121.5, 58, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                        o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                        n: ["0p667_1_1_0", "0p667_1_1_0", "0p667_1_0p333_0"],
                        t: 4,
                        s: [0, 0, 100],
                        e: [100, 100, 100]
                      },
                      {
                        i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                        o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                        n: [
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0"
                        ],
                        t: 12,
                        s: [100, 100, 100],
                        e: [100, 100, 100]
                      },
                      {
                        i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                        o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                        n: [
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0"
                        ],
                        t: 170,
                        s: [100, 100, 100],
                        e: [0, 0, 100]
                      },
                      { t: 178 }
                    ],
                    ix: 6
                  }
                },
                ao: 0,
                w: 243,
                h: 116,
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 24,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [711.179, 827.235, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-5.38, -76.512],
                              [0.32, -20.102],
                              [5.38, 76.508],
                              [-5.38, 76.508]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.027450999245,
                            0.027450999245,
                            0.027450999245,
                            0.079999998212
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 8, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 25,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [985.142, 456.853, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-0.54, 2.05],
                              [0, 0],
                              [0, 0],
                              [1.88, 0],
                              [0.35, -1.21],
                              [0, 0],
                              [2.06, -0.07],
                              [0, 0],
                              [0.38, -0.41],
                              [-1.86, 0.07],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0.53, -1.81],
                              [-1.26, 0],
                              [0, 0],
                              [-0.58, 1.98],
                              [0, 0],
                              [-0.55, 0.02],
                              [-1.27, 1.36],
                              [0, 0],
                              [2.11, -0.07]
                            ],
                            v: [
                              [43.381, 41.778],
                              [44.051, 39.188],
                              [68.631, -45.412],
                              [65.921, -49.032],
                              [63.201, -46.992],
                              [38.461, 37.908],
                              [34.041, 41.348],
                              [-66.729, 44.848],
                              [-68.179, 45.518],
                              [-66.579, 49.028],
                              [38.921, 45.348]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.149019996793,
                            0.149019996793,
                            0.149019996793,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 26,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [694.622, 317.856, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [14.54, -9.96],
                              [0, 0.6],
                              [7.92, 0],
                              [0, -7.93],
                              [-7.93, 0],
                              [-1.62, 0.63],
                              [-8.36, -10.7],
                              [0, 0],
                              [-12.03, -8.74]
                            ],
                            o: [
                              [0.07, -0.59],
                              [0, -7.93],
                              [-7.93, 0],
                              [0, 7.92],
                              [1.84, 0],
                              [-1.42, 13.19],
                              [18.53, 4.79],
                              [0, 0],
                              [-7.01, -22.37]
                            ],
                            v: [
                              [-9.377, -22.015],
                              [-9.267, -23.805],
                              [-23.607, -38.155],
                              [-37.957, -23.805],
                              [-23.607, -9.465],
                              [-18.377, -10.445],
                              [1.493, 37.755],
                              [20.143, 2.495],
                              [37.953, -16.635]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.105881996453, 0.066666997969, 0.22352899611, 1],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 27,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [855.015, 766.564, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-30.706, 95.942],
                              [-10.977, -80.998],
                              [-10.977, -89.958],
                              [15.764, -95.938],
                              [24.283, -43.938],
                              [30.704, 3.882],
                              [24.283, 24.802],
                              [3.363, 95.942]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.101960784314,
                            0.101960784314,
                            0.101960784314,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 10, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 28,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [793.541, 724.222, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [26.69, 10.39],
                              [-62.12, -24.81],
                              [-33.89, 7.06],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-6.92, -31.59],
                              [0, 0],
                              [11.36, 58.58],
                              [0, 0],
                              [14.25, -1.81]
                            ],
                            o: [
                              [0, 0],
                              [50.37, 20.11],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [4.64, 32.82],
                              [0, 0],
                              [0, 0],
                              [-9.04, -46.62],
                              [-15.63, 0],
                              [-27.15, 3.46]
                            ],
                            v: [
                              [-142.856, -151.029],
                              [-93.836, -52.399],
                              [50.494, -46.849],
                              [50.494, -38.649],
                              [30.764, 138.281],
                              [64.834, 138.281],
                              [85.754, 67.151],
                              [90.594, 51.391],
                              [107.874, 151.031],
                              [138.954, 144.851],
                              [138.954, -90.659],
                              [-19.006, -151.029],
                              [-63.606, -147.339]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.956862986088,
                            0.603922009468,
                            0.176470994949,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 29,
                ty: 0,
                nm: "shoeR",
                refId: "comp_3",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 0,
                        s: [0],
                        e: [-12]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 22.857,
                        s: [-12],
                        e: [14.425]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 44.571,
                        s: [14.425],
                        e: [-12]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 68.571,
                        s: [-12],
                        e: [14.425]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 90.286,
                        s: [14.425],
                        e: [-12]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 114.286,
                        s: [-12],
                        e: [14.425]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 136,
                        s: [14.425],
                        e: [-12]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 160,
                        s: [-12],
                        e: [0]
                      },
                      { t: 180 }
                    ],
                    ix: 10
                  },
                  p: { a: 0, k: [916, 909.25, 0], ix: 2 },
                  a: { a: 0, k: [16.5, 20, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                w: 75,
                h: 35,
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 30,
                ty: 0,
                nm: "shoeL",
                refId: "comp_4",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 0,
                        s: [0],
                        e: [12]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 23,
                        s: [12],
                        e: [-8.682]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 45,
                        s: [-8.682],
                        e: [12]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 69,
                        s: [12],
                        e: [-8.682]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 91,
                        s: [-8.682],
                        e: [12]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 114,
                        s: [12],
                        e: [-8.682]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 136,
                        s: [-8.682],
                        e: [12]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 160,
                        s: [12],
                        e: [0]
                      },
                      { t: 180 }
                    ],
                    ix: 10
                  },
                  p: { a: 0, k: [830.75, 890.5, 0], ix: 2 },
                  a: { a: 0, k: [16.25, 19.25, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                w: 75,
                h: 43,
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 31,
                ty: 4,
                nm: "hand",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 0,
                        s: [0],
                        e: [-20]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 20,
                        s: [-20],
                        e: [0]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 39,
                        s: [0],
                        e: [-20]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 60,
                        s: [-20],
                        e: [0]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 80,
                        s: [0],
                        e: [-20]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 100,
                        s: [-20],
                        e: [-20]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 120,
                        s: [-20],
                        e: [0]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 140,
                        s: [0],
                        e: [-20]
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 160,
                        s: [-20],
                        e: [0]
                      },
                      { t: 180 }
                    ],
                    ix: 10
                  },
                  p: { a: 0, k: [922.049, 487.373, 0], ix: 2 },
                  a: { a: 0, k: [84.5, -111.5, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-8.79, -5.62],
                              [-4, -2.23],
                              [0.36, -1.42],
                              [7.25, 3.49],
                              [2.22, -0.02],
                              [-1.22, 0],
                              [6.91, -1.15],
                              [11.51, 3.76],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [2.11, 1.35],
                              [8.46, 4.73],
                              [-0.7, 2.81],
                              [-5.1, -2.45],
                              [-5.39, 0.05],
                              [1.8, 0],
                              [-6.91, 1.15],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [82.245, -118.934],
                              [121.985, -121.324],
                              [131.765, -115.684],
                              [149.785, -103.984],
                              [134.225, -108.334],
                              [121.985, -113.134],
                              [105.555, -108.174],
                              [123.755, -101.354],
                              [88.815, -105.784],
                              [82.245, -104.584]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 4",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.956862986088,
                            0.686275005341,
                            0.666666984558,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Shape",
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 32,
                ty: 4,
                nm: "Shape",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [837.549, 598.873, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [11.4, -19],
                              [-6.13, -9.08],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [1.81, 15.61],
                              [1.89, 6]
                            ],
                            o: [
                              [-8.89, 14.82],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [14, 1.75],
                              [0, 0],
                              [-0.5, -4.39],
                              [-10.76, -34.08]
                            ],
                            v: [
                              [-145.839, -287.771],
                              [-140.599, -249.501],
                              [-143.619, -228.331],
                              [-146.469, -210.761],
                              [-115.599, -210.761],
                              [-115.599, -225.481],
                              [-115.599, -239.891],
                              [-96.599, -245.051],
                              [-93.139, -266.571],
                              [-96.599, -282.111]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-4.871, 287.535],
                              [-4.871, 260.045],
                              [-4.871, 242.705],
                              [17.839, 240.315],
                              [9.469, 287.535]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 2",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 2,
                        ty: "sh",
                        ix: 3,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [77.613, 304.875],
                              [72.233, 274.985],
                              [68.053, 260.635],
                              [90.173, 255.855],
                              [94.353, 302.475]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 3",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.956862986088,
                            0.686275005341,
                            0.666666984558,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Shape",
                    np: 5,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 33,
                ty: 4,
                nm: "Shape",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1083.986, 458.749, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-31.697, -3.548],
                              [32.523, -5.738]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-33.064, -1.645],
                              [-33.064, -4.915],
                              [33.066, -7.105],
                              [33.066, -0.005]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 2",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 2,
                        ty: "sh",
                        ix: 3,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-33.064, -1.643],
                              [28.146, -3.553]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 3",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 3,
                        ty: "sh",
                        ix: 4,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-33.064, -1.64],
                              [-33.064, 0.27]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 4",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 4,
                        ty: "sh",
                        ix: 5,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-33.064, 0.27],
                              [23.496, -1.64]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 5",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 5,
                        ty: "sh",
                        ix: 6,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-33.064, 0.272],
                              [-33.064, 3.692],
                              [-33.064, 7.102],
                              [33.066, 7.102],
                              [33.066, 3.552]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 6",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 6,
                        ty: "sh",
                        ix: 7,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [33.066, -0.001],
                              [-30.604, 1.909]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 7",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 7,
                        ty: "sh",
                        ix: 8,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [33.064, -0.001],
                              [33.064, 1.779]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 8",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 8,
                        ty: "sh",
                        ix: 9,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [33.064, 3.548],
                              [-26.506, 4.648]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 9",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 9,
                        ty: "sh",
                        ix: 10,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [33.064, 3.555],
                              [33.064, 1.775]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 10",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 10,
                        ty: "sh",
                        ix: 11,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [33.066, 1.773],
                              [6.286, 1.913]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 11",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false
                      },
                      {
                        ty: "st",
                        c: { a: 0, k: [0, 0, 0, 0.189999997616], ix: 3 },
                        o: { a: 0, k: 19, ix: 4 },
                        w: { a: 0, k: 0.547, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Shape",
                    np: 13,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 34,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1083.986, 458.749, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-33.064, -4.915],
                              [33.066, -7.105],
                              [33.066, -0.005],
                              [33.066, 1.775],
                              [33.066, 3.555],
                              [33.066, 7.105],
                              [-33.064, 7.105],
                              [-33.064, 3.685],
                              [-33.064, 0.275],
                              [-33.064, -1.645]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.901961028576,
                            0.905882000923,
                            0.952941000462,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 35,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1082.346, 458.789, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 1.2],
                              [0, 0],
                              [-1.18, 0.05],
                              [0, 0],
                              [0, -1.24],
                              [0, 0],
                              [1.2, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, -1.18],
                              [0, 0],
                              [1.24, -0.04],
                              [0, 0],
                              [0, 1.2],
                              [0, 0],
                              [-1.21, 0]
                            ],
                            v: [
                              [-34.156, 7.07],
                              [-34.156, -4.76],
                              [-32.046, -6.95],
                              [31.893, -9.25],
                              [34.154, -7.06],
                              [34.154, 7.07],
                              [31.973, 9.25],
                              [-31.967, 9.25]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.298038989305, 0.29019600153, 0.654901981354, 1],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 36,
                ty: 4,
                nm: "Shape",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1073.909, 476.078, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [31.548, -5.751],
                              [-32.702, -5.691]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [32.986, -3.882],
                              [32.866, -7.162],
                              [-33.294, -7.042],
                              [-33.044, 0.058]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 2",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 2,
                        ty: "sh",
                        ix: 3,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [32.984, -3.883],
                              [-28.256, -3.663]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 3",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 3,
                        ty: "sh",
                        ix: 4,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [32.986, -3.886],
                              [33.046, -1.976]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 4",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 4,
                        ty: "sh",
                        ix: 5,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [33.054, -1.972],
                              [-23.546, -1.912]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 5",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 5,
                        ty: "sh",
                        ix: 6,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [33.046, -1.973],
                              [33.166, 1.437],
                              [33.286, 4.857],
                              [-32.804, 7.167],
                              [-32.924, 3.607]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 6",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 6,
                        ty: "sh",
                        ix: 7,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-33.047, 0.066],
                              [30.653, -0.255]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 7",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 7,
                        ty: "sh",
                        ix: 8,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-33.046, 0.061],
                              [-32.986, 1.841]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 8",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 8,
                        ty: "sh",
                        ix: 9,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-32.924, 3.608],
                              [26.646, 2.628]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 9",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 9,
                        ty: "sh",
                        ix: 10,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-32.925, 3.607],
                              [-32.985, 1.837]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 10",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 10,
                        ty: "sh",
                        ix: 11,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-32.985, 1.838],
                              [-6.215, 1.038]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 11",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false
                      },
                      {
                        ty: "st",
                        c: { a: 0, k: [0, 0, 0, 0.189999997616], ix: 3 },
                        o: { a: 0, k: 19, ix: 4 },
                        w: { a: 0, k: 0.547, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Shape",
                    np: 13,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 37,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1073.906, 476.078, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [32.869, -7.162],
                              [-33.291, -7.042],
                              [-33.041, 0.058],
                              [-32.981, 1.838],
                              [-32.921, 3.608],
                              [-32.801, 7.158],
                              [33.289, 4.848],
                              [33.169, 1.438],
                              [33.049, -1.972],
                              [32.989, -3.882]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.901961028576,
                            0.905882000923,
                            0.952941000462,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 38,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1075.545, 476.085, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0.04, 1.21],
                              [0, 0],
                              [1.18, 0],
                              [0, 0],
                              [-0.04, -1.24],
                              [0, 0],
                              [-1.21, 0.04],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [-0.04, -1.18],
                              [0, 0],
                              [-1.24, 0],
                              [0, 0],
                              [0.05, 1.21],
                              [0, 0],
                              [1.21, -0.04]
                            ],
                            v: [
                              [34.386, 4.754],
                              [33.966, -7.066],
                              [31.786, -9.176],
                              [-32.194, -9.246],
                              [-34.384, -6.986],
                              [-33.894, 7.134],
                              [-31.624, 9.244],
                              [32.276, 7.014]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.486274987459,
                            0.584313988686,
                            0.933332979679,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 39,
                ty: 4,
                nm: "Shape",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1077.427, 491.13, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-31.697, -3.549],
                              [32.513, -5.739]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-33.064, -1.644],
                              [-33.064, -4.914],
                              [33.066, -7.104],
                              [33.066, -0.004]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 2",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 2,
                        ty: "sh",
                        ix: 3,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-33.064, -1.642],
                              [28.146, -3.552]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 3",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 3,
                        ty: "sh",
                        ix: 4,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-33.064, -1.639],
                              [-33.064, 0.27]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 4",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 4,
                        ty: "sh",
                        ix: 5,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-33.064, 0.27],
                              [23.496, -1.639]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 5",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 5,
                        ty: "sh",
                        ix: 6,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-33.064, 0.273],
                              [-33.064, 3.693],
                              [-33.064, 7.103],
                              [33.066, 7.103],
                              [33.066, 3.553]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 6",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 6,
                        ty: "sh",
                        ix: 7,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [33.066, 0],
                              [-30.604, 1.91]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 7",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 7,
                        ty: "sh",
                        ix: 8,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [33.064, 0],
                              [33.064, 1.78]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 8",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 8,
                        ty: "sh",
                        ix: 9,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [33.064, 3.549],
                              [-26.506, 4.649]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 9",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 9,
                        ty: "sh",
                        ix: 10,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [33.064, 3.556],
                              [33.064, 1.776]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 10",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ind: 10,
                        ty: "sh",
                        ix: 11,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [33.066, 1.774],
                              [6.286, 1.914]
                            ],
                            c: false
                          },
                          ix: 2
                        },
                        nm: "Path 11",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false
                      },
                      {
                        ty: "st",
                        c: { a: 0, k: [0, 0, 0, 0.189999997616], ix: 3 },
                        o: { a: 0, k: 19, ix: 4 },
                        w: { a: 0, k: 0.547, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Shape",
                    np: 13,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 40,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1077.427, 491.13, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            v: [
                              [-33.064, -4.914],
                              [33.066, -7.104],
                              [33.066, -0.004],
                              [33.066, 1.776],
                              [33.066, 3.556],
                              [33.066, 7.106],
                              [-33.064, 7.106],
                              [-33.064, 3.686],
                              [-33.064, 0.276],
                              [-33.064, -1.644]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.901961028576,
                            0.905882000923,
                            0.952941000462,
                            1
                          ],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              },
              {
                ddd: 0,
                ind: 41,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1075.786, 491.171, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 }
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 1.2],
                              [0, 0],
                              [-1.18, 0.05],
                              [0, 0],
                              [0, -1.24],
                              [0, 0],
                              [1.2, 0],
                              [0, 0]
                            ],
                            o: [
                              [0, 0],
                              [0, -1.18],
                              [0, 0],
                              [1.24, -0.04],
                              [0, 0],
                              [0, 1.2],
                              [0, 0],
                              [-1.21, 0]
                            ],
                            v: [
                              [-34.156, 7.07],
                              [-34.156, -4.76],
                              [-32.046, -6.95],
                              [31.893, -9.25],
                              [34.154, -7.06],
                              [34.154, 7.07],
                              [31.973, 9.25],
                              [-31.967, 9.25]
                            ],
                            c: true
                          },
                          ix: 2
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.486274987459, 0.643136978149, 1],
                          ix: 4
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform"
                      }
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false
                  }
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0
              }
            ],
            markers: []
          },
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        }}
        height={200}
        width={500}
      />
    );
  }
}
