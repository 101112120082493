import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Jumbotron } from "react-bootstrap";
import { FaTelegram, FaWhatsapp } from "react-icons/fa";

function Atendimento() {

  const seo_attr = {
    title: "Atendimento gratuito sobre Coronavírus | Central Corona",
    description: "Fique em casa! Tire suas dúvidas sobre o coronavírus (COVID-19) com médicos " +
                 "voluntários. O atendimento é gratuito e online."
  };

  document.title = seo_attr.title;
  document.getElementsByTagName("meta").namedItem("description").content = seo_attr.description;

  return (
    <Jumbotron>
      <h4>Clique no seu aplicativo de chat preferido:</h4>
      <p style={{ color: "silver" }}>
        Ao continuar, você concorda com os{" "}
        <a
          href="https://suporte.centralcorona.com/hc/pt-br/articles/360007077717-Termos-de-Uso"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span style={{ color: "grey" }}>Termos de serviço</span>
        </a>
        .
      </p>
      <div style={{ marginTop: "10px" }}>
        <div style={{ margin: "10px" }}>
          <a href="https://wa.me/551123918563">
            <FaWhatsapp color="#00e676" size="5em" />
          </a>
          <p style={{ marginTop: "5px", fontSize: "12px", color: "grey" }}>
            WhatsApp
          </p>
        </div>
        {/* <div style={{ margin: "10px" }}>
          <a href="http://m.me/112165860425044">
            <FaFacebookMessenger color="#0183ff" size="5em" />
          </a>
          <p style={{ marginTop: "5px", fontSize: "12px", color: "grey" }}>
            Facebook Messenger
          </p>
        </div> */}
        <div style={{ margin: "10px" }}>
          <a href="https://t.me/centralcorona_bot">
            <FaTelegram color="#0088cc" size="5em" />
          </a>
          <p style={{ marginTop: "5px", fontSize: "12px", color: "grey" }}>
            Telegram
          </p>
        </div>
      </div>
    </Jumbotron>
  );
}

export default Atendimento;
