import React from "react";
import fuze from "./img/partners/fuze.png";
import bird from "./img/partners/bird.svg";
import enlink from "./img/partners/enlink.png";
import moises from "./img/partners/moises.svg";

export default () => {

    return(
        <div style={{ marginTop: "40px", paddingBottom: "30px" }}>
        <h1>Obrigado!</h1>
        <div style={{ margin: "0 auto", maxWidth:800 }}>
        <p>
        A partir do dia 29 de setembro de 2020, a Central Corona encerra suas atividades e agradece a todos os voluntários que doaram seu tempo com o único intuito de ajudar a população brasileira durante os momentos críticos da pandemia. 
            <br/>
            <br/>
            A Central Corona teve seu papel no momento mais crítico quando o conhecimento sobre a pandemia era limitado e havia grande sobrecarga nos sistemas de saúde. 
            <br/>
            <br/>
            Com a redução dos casos, desafogamento dos hospitais e maior conscientização da população, a demanda de atendimentos diminuiu para um nível mínimo e estável. Após milhares de atendimentos bem sucedidos, entendemos que concluímos nossa missão com êxito. Cumprimos nosso papel social proporcionando atendimento médico gratuito de forma acessível e instantânea a cidadãos de todo o Brasil.
            <br/>
            <br/>
            Em nota, gostaríamos de agradecer em especial a voluntária Gláucia Luna, que durante mais de 9 meses dedicou seu tempo quase que diariamente para atender de forma gratuita pessoas de todo o país através da Central Corona. Gláucia, sem você esta iniciativa não seria a mesma.

            <br/>
            <br/>
            Agradecemos também aos parceiros MessageBird, que proporcionou a plataforma utilizada pelo projeto, e a Enlink, que nos ajudou com toda comunicação e redes sociais.
            <br/>
            <br/>

            Atenciosamente,<br/>
            <strong>Geraldo Ramos, Eddie Hsu, Heloísa Estrela e toda equipe de voluntários da CentralCorona.</strong>
            <br/>
            <br/>
        </p>
        </div>
        <div style={{ marginTop: "20px", paddingBottom: "30px" }}>
          <p
            style={{ fontSize: "12px", color: "silver", letterSpacing: "1px" }}
          >
            PARCEIROS
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              href="https://moises.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="moises" src={moises} alt="Moises" />
            </a>
            <a href="https://fuze.cc" target="_blank" rel="noopener noreferrer">
              <img className="fuze" src={fuze} alt="Fuze"></img>
            </a>
            <a
              href="https://www.messagebird.com/en/inbox-for-good"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="bird" src={bird} alt="MessageBird"></img>
            </a>

            <a
              href="http://agenciaenlink.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="enlink" src={enlink} alt="Enlink"></img>
            </a>
          </div>
        </div>
        </div>
    )

}