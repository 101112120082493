import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AnimationMobile from "./img/AnimationMobile";
import Animation from "./img/Animation";
import Team from "./Team";
import ComoFunciona from "./ComoFunciona";
import fuze from "./img/partners/fuze.png";
import bird from "./img/partners/bird.svg";
import enlink from "./img/partners/enlink.png";
import moises from "./img/partners/moises.svg";

function Home() {
  const seo_attr = {
    title: "Central Corona | Atendimento online gratuito sobre coronavírus",
    description:
      "Atendimento online gratuito para pacientes com suspeita ou dúvidas sobre o " +
      "Coronavírus (COVID-19).",
  };

  document.title = seo_attr.title;
  document.getElementsByTagName("meta").namedItem("description").content =
    seo_attr.description;

  return (
    <>
      <div className="container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="headline">
            <h1>
              Converse com um médico voluntário via{" "}
              <span style={{ fontWeight: "100" }}>WhatsApp ou Telegram</span>
            </h1>
            <p style={{ fontSize: "16px" }}>
              Este serviço <strong>gratuito</strong> tem o propósito de auxiliar
              pacientes com um primeiro teleatendimento médico caso haja
              suspeita de Corona Vírus.
            </p>

            <div className="buttonDesktop">
              <Link to="/atendimento-coronavirus">
                <Button size="lg" variant="primary">
                  Iniciar Atendimento
                </Button>
              </Link>
            </div>
          </div>
          <div className="animationDesktop" maxWidth="400px">
            <Link to="/atendimento-coronavirus">
              <Animation />
            </Link>
          </div>
          <div className="animationMobile" maxWidth="400px">
            <Link to="/atendimento-coronavirus">
              <AnimationMobile />
            </Link>
          </div>
        </div>
        <div className="buttonMobile">
          <Link to="/atendimento-coronavirus">
            <Button size="lg" variant="primary">
              Iniciar Atendimento
            </Button>
          </Link>
        </div>
        <br />
        <div style={{ marginTop: "20px", paddingBottom: "30px" }}>
          <p
            style={{ fontSize: "12px", color: "silver", letterSpacing: "1px" }}
          >
            PARCEIROS
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              href="https://moises.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="moises" src={moises} alt="Moises" />
            </a>
            <a href="https://fuze.cc" target="_blank" rel="noopener noreferrer">
              <img className="fuze" src={fuze} alt="Fuze"></img>
            </a>
            <a
              href="https://www.messagebird.com/en/inbox-for-good"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="bird" src={bird} alt="MessageBird"></img>
            </a>

            <a
              href="http://agenciaenlink.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="enlink" src={enlink} alt="Enlink"></img>
            </a>
          </div>
        </div>
      </div>
      <div className="subSection">
        <div className="container">
          <ComoFunciona />
        </div>
      </div>
      <div className="subSection whiteBg">
        <div className="container">
          <Team />
        </div>
      </div>
    </>
  );
}

export default Home;
